import React, {useState, useEffect, useCallback, useRef, useContext} from 'react'
import './users.css'
import '../../components/table/table.css'
import usePagination from "../../hooks/usePagination";
import {LoadingCards, LoadingSkeletonTable} from "../../components/loading-skeleton/loading-skeleton";
import { thousandSeparator } from '../dashboard/dashboard';
import { TableCard } from '../../components/table/table';
import Select from 'react-select'
import { Checkbox, CheckboxGroup } from '@trendmicro/react-checkbox';
import { useWindowDimensions } from '../../App';
import '@trendmicro/react-checkbox/dist/react-checkbox.css';
import '../../components/filter/filter.css'
import AddUserPs from '../../components/add-user-ps/add-user-ps';
const axios = require('axios').default;

// Function for sorting ASC and DESC
export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };

const Users = () => {
    const { width } = useWindowDimensions();
    const user = JSON.parse(localStorage.getItem("user"));
    const [result, setResult] = useState([]);
    const [resultStat, setResultStat] = useState([]);
    const [resultTotal, setResultTotal] = useState([]);
    const [cities, setCities] = useState([]);
    const [region, setRegion] = useState('');
    const [status, setStatus] = useState('');
    const [withOrders, setWithOrders] = useState('');
    const [withListings, setWithListings] = useState('');
    const [withBookings, setWithBookings] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [statusOrders, setStatusOrders] = useState('');
    const [xPos, setXpos] = useState(0);
    const [count, setCount] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showFullInput, setShowFullInput] = useState(false);

    const regionsRef = useRef(null);
    const statusRef = useRef(null);
    const statusOrdersRef = useRef(null);

    const getUsers = (page) => {
        let filter = {};

        if (region !== '' && user.username === '7000') {
            filter["region"] = region;
        }

        if (user.username !== '7000') {
            filter["region"] = user.city;
        }

        if (status !== '') {
            filter["status"] = status;
        }

        if (withOrders !== '') {
            filter["orders"] = withOrders;
        }

        if (withBookings !== '') {
            filter["bookings"] = withBookings;
        }

        if (withListings !== '') {
            filter["listings"] = withListings;
        }

        if (filter) {
            filter = JSON.stringify(filter);
        }
        
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: 'get_dashboard_users_list',
                per_page: 15,
                page: page ? page : 1,
                filters: filter,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setResultTotal(response.data.total_users_count);
                setResult(response.data.users);
                setLoadingTable(false);
            })
            .catch(function (error) {
                console.log('Error JSON', error.toJSON());
                console.log('Error', error.message);
            });
    };

    const getUserStats = () => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: 'get_dashboard_users_stat',
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setResultStat(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    };

    const getRegions = () => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data:{
                type: "get_regions"
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setCities(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    };

    let regions = Object.values(cities).map(function(item, index) {
        const options = {index: index, value: item.term_id, label: item.name};  
        return options;
    });

    const changeRegionFunc = (region) => {
        setRegion(region);
    }

    const {
        firstContentIndex,
        lastContentIndex,
        nextPage,
        prevPage,
        page,
        setPage,
        totalPages
    } = usePagination({
        contentPerPage: 15,
        count: resultTotal ?? 0,
    });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      margin: '4px 0px',
      padding: 5,
      color: state.isSelected ? 'white' : 'black',
      backgroungColor: state.isSelected ? 'blue' :'#B1DBFB',
      borderRadius: '8px',
    }),
    control: () => ({
      marginBottom: '10px',
      display: 'flex',
      width: width <= 900 ? 300 : 345,
      color: '#000',
      border: '1px solid #C4C4C4',
      borderRadius: '8px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#000',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)': '',
    })
  }

    const checkBoxOnValidation = useCallback(
      () => {
        setStatus("on_validation");
      }, [''],
    )
    const checkBoxConfirmed = useCallback(
        () => {
          setStatus("confirmed");
        }, [''],
      )
      const checkBoxNotConfirmed = useCallback(
        () => {
          setStatus("not_confirmed");
        }, [''],
      )
      const checkBoxWithOrders = useCallback(
        () => {
          setWithOrders("with");
          setStatusOrders("with-orders");
        }, [''],
      )
      const checkBoxWithListings = useCallback(
        () => {
          setWithListings("with");
          setStatusOrders("with-listings");
        }, [''],
      )
      const checkBoxWithBookings = useCallback(
        () => {
          setWithBookings("with");
          setStatusOrders("with-bookings");
        }, [''],
      )
      const checkBoxWithoutOrders = useCallback(
        () => {
          setWithOrders("without");
          setStatusOrders("without-orders");
        }, [''],
      )
    
    const clear = () => {
        setStatus('');
        setStatusOrders('');
        setWithListings("");
        setWithBookings("");
        setWithOrders("");

        regionsRef.current.selectOption('');
        setRegion('');

        statusRef.current.value = "";
        statusOrdersRef.current.value = "";
    }

    const refresh = () => {
        setShowFilter(!showFilter);
        setLoadingTable(true);
        getUsers();
    }

    const refreshTable = () => {
        setLoadingTable(true);
        getUsers();
    }

    const pagino = (page) => {
        setLoadingTable(true);
        getUsers(page);
    }

    const {items, requestSort, sortConfig} = useSortableData(result == null || result == undefined ? [] : Object.values(result));

    const filterCounting = () => {
        let sum = 0;
        if (region !== '' && region !== undefined) {sum = sum + 1}
        if (status !== '') {sum = sum + 1}
        if (statusOrders !== '') {sum = sum + 1}
        return sum;
    }

    const onClick = (direction) => {
        let res = totalPages / 10;
        let c = Math.ceil(res)
        setCount(c);
        let plu = count + 1;
        let min = count - 1;
        (direction === "left") ? setXpos(x => x - 100) : setXpos(x => x + 100);
        (direction === "left") ? setCount(plu) : setCount(min);
    }

    useEffect(() => {
        getUserStats();
        getRegions();
        getUsers();
    }, []);

    return (
        <div className='users_wrapper'>
        {showModal ? <AddUserPs closeModal={() => setShowModal(!showModal)} refresh={() => refreshTable()} /> : null}
        {loading ? <LoadingCards /> 
        : 
        <TableCard items={[
                    {
                        count: thousandSeparator(resultStat.total_users),
                        description: 'Активных пользователей',
                        icon: 'Profile'
                    },
                    {
                        count: thousandSeparator(resultStat.not_confirmed_users),
                        description: 'Не подтвержденных пользователей',
                        icon: 'Close'
                    },
                    {
                        count: thousandSeparator(resultStat.confirmed_users),
                        description: 'Подтвержденные пользователи',
                        icon: 'Tick'
                    },
                    {
                        count: thousandSeparator(resultStat.on_validation),
                        description: 'Заявка на авторизацию',
                        icon: 'Clock'
                    },
                    {
                        count: thousandSeparator(resultStat.blocked),
                        description: 'Заблокированных пользователей',
                        icon: 'blocked-users'
                    },
            ]} />
            }
            <div className='users'>
                <div className='users_header'>
                    <>
                    <div className='title'><span style={{marginRight: '7px'}} className="material-symbols-outlined">group</span><span className='d600none'>Пользователи</span></div>
               
                    </>
                    <div style={{alignItems: 'center'}} className='flex'>
                    {user.username === '7000' ?
                    <div title='Добавить пользователя ПоедемСами.рф' style={{marginRight: '7px'}} className='table_input-btn'>
                        <span onClick={() => setShowModal(!showModal)} class="material-symbols-outlined">person_add</span>
                    </div>
                    : null}
                    <div title='Обновить таблицу' style={{marginRight: '7px'}} className='table_input-btn'>
                        <span onClick={refreshTable} class="material-symbols-outlined">refresh</span>
                    </div>
                    <div title='Фильтры' className='filter__centered flex-column'>
                        <div onClick={() => setShowFilter(!showFilter)} className='users_filter'>
                            Фильтры
                            {filterCounting() == '0' ? null : <div className='filter_counter'>{filterCounting()}</div>}
                            <span style={{marginLeft: '7px', display: 'flex', alignItems: 'center'}}>
                                {showFilter ? 
                                <span className="material-symbols-outlined">close</span>
                                : <span className="material-symbols-outlined">tune</span>}
                            </span>
                        </div>
                        <div>
                            {showFilter ? 
                            <div className='filter users_filter-block'>
                                {user.username === '7000' ? 
                                <Select
                                    ref={regionsRef}
                                    placeholder="Город"
                                    isSearchable={false}
                                    components={{ IndicatorSeparator:() => null }}
                                    options={regions}
                                    value={regions.filter(function(option) {
                                        return option.label === region;
                                    })}
                                    onChange={e => changeRegionFunc(e.label)}
                                    styles={customStyles}
                                />
                                : null }
                                <div className='filter_checkbox-block'>
                                <div className='filter_checkbox-column'>
                                    <Checkbox ref={statusOrdersRef} checked={statusOrders == "with-orders" ? true : false} onChange={checkBoxWithOrders} className="filter_checkbox-column-item" label="С объявлениями" />
                                    <Checkbox ref={statusOrdersRef} checked={statusOrders == "without-orders" ? true : false} onChange={checkBoxWithoutOrders} className="filter_checkbox-column-item" label="Без объявлений" />
                                    <Checkbox ref={statusOrdersRef} checked={statusOrders == "with-listings" ? true : false} onChange={checkBoxWithListings} className="filter_checkbox-column-item" label="С заказами" />
                                    <Checkbox ref={statusOrdersRef} checked={statusOrders == "with-bookings" ? true : false} onChange={checkBoxWithBookings} className="filter_checkbox-column-item" label="С бронями" />
                                </div>
                                <div className='filter_checkbox-column'>
                                    <Checkbox ref={statusRef} checked={status == "confirmed" ? true : false} onChange={checkBoxConfirmed} className="filter_checkbox-column-item" label="Подтвержденные" />
                                    <Checkbox ref={statusRef} checked={status == "not_confirmed" ? true : false} onChange={checkBoxNotConfirmed} className="filter_checkbox-column-item" label="Не подтвержденные" />
                                    <Checkbox ref={statusRef} checked={status == "on_validation" ? true : false} onChange={checkBoxOnValidation} className="filter_checkbox-column-item" label="На проверке" />
                                </div>
                                </div>
                                <div className='filter_button-block'>
                                    <div onClick={() => clear()} className='filter_button-block-btn filter_button-block-btn--reset'>Сбросить фильтры</div>
                                    <div onClick={() => refresh()} className='filter_button-block-btn'>Применить</div>
                                </div>
                            </div> 
                            : null}
                        </div>
                        </div>
                        <div title='Поиск по таблице' onClick={() => setShowFullInput(!showFullInput)} className='table_input-btn'><span className="material-symbols-outlined">search</span></div>
                        {showFullInput ? <div className='full-input table_input input'>
                            <input onChange={e => {setSearchTerm(e.target.value)}} className='input_text' placeholder='Поиск по имени, логину, номеру или городу'/>
                            <span style={{cursor: 'pointer'}} onClick={() => setShowFullInput(!showFullInput)} className="material-symbols-outlined">close</span>
                        </div> : null}
                </div>
            </div>
            </div>
            <div className='table_wrapper_overflow'>
            <table className="table">
                <thead className="table_head">
                    <tr>
                        <th
                            style={{
                            paddingLeft: '10px'
                        }}>
                            <button type="button" 
                            onClick={() => requestSort('login')}
                         className='table_title'>
                                Логин
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button"  
                            onClick={() => requestSort('first_name')}
                         className='table_title'>
                                Имя пользователя
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button"  
                            onClick={() => requestSort('phone')}
                         className='table_title'>
                                Телефон
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button"  
                            onClick={() => requestSort('confirmed')}
                         className='table_title'>
                                Статус
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" 
                            onClick={() => requestSort('orders_count')}
                         className='table_title'>
                                Заказы
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button"  
                            onClick={() => requestSort('bookings_count')}
                         className='table_title'>
                                Брони
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button"  
                            onClick={() => requestSort('active_listings')}
                         className='table_title'>
                                Акт. объ.
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" 
                            onClick={() => requestSort('city')}
                         className='table_title'>
                                Город
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" 
                            onClick={() => requestSort('rating')}
                         className='table_title'>
                                Рейтинг
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                    </tr>
                </thead>
            {loadingTable ? <LoadingSkeletonTable items={[{},{},{},{},{},{}]} /> 
            :
            <> 
               {result === null || result === undefined 
               ? <tbody>
                    <tr>
                        <td colSpan={2}>
                            <div className='no-data_table-item'>
                                <img className='no-data_folder' src={require("../../assets/empty-folder.png")} alt='no-data'/>
                                <div className='no-data_title'>Нет данных</div>
                                <div className='no-data_subtitle'>Сбросьте фильтры чтобы продолжить</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
               : 
               <tbody>
                    {Object
                        .keys(items)
                        .filter(key => (
                            items[key].login.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            items[key].first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            items[key].last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            items[key].phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            items[key].city.toLowerCase().includes(searchTerm.toLowerCase())
                            ))
                        .map((item) => {
                            return (
                                <tr key={items[item].id} className="table_body">
                                    <td
                                        style={{
                                        paddingLeft: '10px'
                                    }}
                                        title="Логин"
                                        className="table-body_item">{items[item].login}</td>
                                    <td className="table-body_item">
                                        {items[item].avatar
                                            ? <img className='table-body_img' src={items[item].avatar}/>
                                            : <span className="material-symbols-outlined no-img_table">account_circle</span>}
                                        {items[item].first_name
                                            ? <span>
                                                    {items[item].first_name}&#160;
                                                    {items[item].last_name}
                                                </span>
                                            : <span className='no-data'>
                                                Нет имени
                                            </span>}
                                    </td>
                                    {items[item].phone
                                        ? <td title="Телефон" className="table-body_item">{items ? items[item].phone : "Нет данных"}</td>
                                        : <td className="table-body_item no-data">Нет номера</td>}
                                    <td
                                        style={{textAlign: 'center'}} className="table-body_item">
                                        {(() => {
                                            switch (items[item].confirmed) {
                                                case true:
                                                    return <span
                                                            title='Подтвержденный пользователь'
                                                            style={{color: '#4CBB17'}}
                                                            className="material-symbols-outlined">check_circle</span>;
                                                case false:
                                                    return <span
                                                            title='Не подтвержденный пользователь'
                                                            style={{color: '#F05050'}}
                                                            className="material-symbols-outlined">
                                                                cancel
                                                            </span>;
                                            }
                                        })()}
                                    </td>
                                    <td title="Заказов у пользователя" className="table-body_item">{items[item].orders_count}</td>
                                    <td title="Броней пользователя" className="table-body_item">{items[item].bookings_count}</td>
                                    <td title="Активные объявления пользователя" className="table-body_item">{items[item].active_listings}</td>
                                    {items[item].city
                                        ? <td className="table-body_item">{items[item].city}</td>
                                        : <td className="table-body_item no-data">Город не указан</td>}
                                        {items[item].rating ? <td style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="table-body_item">
                                         <span style={{fontSize: '20px', verticalAlign: 'middle'}} className="material-symbols-outlined">star</span>{items[item].rating.toFixed(1)}</td> :
                                         <td style={{textAlign: 'center'}} className="table-body_item">
                                         <span style={{fontSize: '20px', verticalAlign: 'middle'}} className="material-symbols-outlined">star</span>--.--</td>}
                                </tr>
                            )
                        })}
                    </tbody>}
                </>
            }
            </table>
            </div>
            { totalPages !== 0 ?
                <div className="pagination">
                <div className="pagination_text">
                    {page} / {totalPages}
                </div>
                {count <= 1 && totalPages !== 0 ?
                <button className={`pagination_page pagination_page--prev disabled`}>
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                :
                <button onClick={() => onClick()} className={`pagination_page pagination_page--prev`}>
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                }
                
                <div className='pagination_outer'>
                    <div style={{transform: `translate(${xPos}%)`}} className='pagination_block'>
                {[...Array(totalPages).keys()].map((el) => (
                    <button
                        onClick={() => {setPage(el + 1); pagino(el + 1)}}
                        key={el}
                        className={`pagination_page ${page === el + 1
                        ? "active"
                        : ""}`}>
                        {el + 1}
                    </button>
                ))}
                </div>
                    </div>
                    {count === Math.ceil(totalPages / 10) ? 
                    <button className="pagination_page pagination_page--next disabled">
                        <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_forward_ios</span>
                    </button>
                    :
                    <button onClick={() => onClick("left")} className="pagination_page pagination_page--next">
                        <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_forward_ios</span>
                    </button>
                }
            </div>
        : null}
    </div>
    );
}

export default Users;