import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './enternewapi.css';
import Modal from '../../components/modal/modal';

export const EnterNewApi = () => {
    const [apiKey, setApiKey] = useState('');
    const [apiKeyNow, setApiKeyNow] = useState('');
    const [showModal, setShowModal] = useState(false);

    const updateApiKey = () => {
        setApiKey('');
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: 'updateApiKey',
                apikey: apiKey,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                console.log("api: " + response.data);
                if (response.data === 'API key updated successfully') {
                    setShowModal(true);
                    getKeyNow();
                }
            })
            .catch(function (response) {
                console.log(response.err);
            });
    }

    const getKeyNow = () => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: 'getApiKey',
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                console.log(response.data);
                setApiKeyNow(response.data[0].apikey);
            })
            .catch(function (response) {
                console.log(response.err);
            });
    }

    useEffect(() => {
        getKeyNow();
    }, []);

    return (
        <div className='enter-new-api'>
            {showModal ? <Modal title="API-ключ был успешно изменен!" message="Нажмите кнопку чтобы вернуться" link='/' /> : null}
            <div className='enter-new-api__title'>Поменять api</div>
            <div className='enter-new-api__input-block'>
                <div className='enter-new-api__input-block-key-now'>Ключ сейчас: {apiKeyNow}</div>
                <input className='enter-new-api__input-block-input' placeholder='Введите новый API-ключ' onChange={(e) => setApiKey(e.target.value)} />
                {apiKey != '' ?
                <button className='enter-new-api__input-block-btn' onClick={() => updateApiKey(apiKey)}>отправить</button>
                :
                <button style={{background: 'grey'}} className='enter-new-api__input-block-btn'>отправить</button>
                }
            </div>
        </div>
    );
}