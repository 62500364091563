import React, {useState, useEffect} from 'react';
import Select from 'react-select'
import axios from 'axios';
import md5 from "md5";
import { server } from '../../var';

const UpdateUser = ({closeModal, item, refresh}) => {
    const [username, setUsername] = useState(item.username);
    const [password, setPassword] = useState(item.password);
    const [city, setCity] = useState(item.city);
    const [cityId, setCityId] = useState(item.cityId);
    const [name, setName] = useState(item.name);
    const [phone, setPhone] = useState(item.phone);
    const [mail, setMail] = useState(item.mail);
    const [tg, setTg] = useState(item.tg);
    const [cities, setCities] = useState([]);

    const updateUser = values => {
        const {username, password, city, cityId, name, phone, mail, tg} = values;
        axios.post(`${server}/updateUser`, {username, password, city, cityId, name, phone, mail, tg})
        .then(res => {
            // console.log(res, "UPDATE");
        })
        .catch(function (error) {
            console.log('Error JSON', error.toJSON());
            console.log('Error', error.message);
        });
    }

    const getRegions = () => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data:{
                type: "get_regions"
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setCities(response.data);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    };

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          margin: '4px 0px',
          padding: 5,
          color: state.isSelected ? 'white' : 'black',
          backgroungColor: state.isSelected ? 'blue' :'#B1DBFB',
          borderRadius: '8px',
        }),
        control: () => ({
          marginTop: "5px",
          display: 'flex',
          width: '100%',
          height: '30px',
          color: '#000',
          fontSize: '14px',
          background: '#FFF',
          border: '1px solid #C4C4C4',
          borderRadius: '8px',
          boxSizing: 'border-box',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#000',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)': '',
        })
    }

    let regions = Object.values(cities).map(function(item, index) {
        const options = {index: index, value: item.term_id, label: item.name};  
        return options;
    });

    useEffect(() => {
        getRegions();
    }, []);

    return (
        <div className='add-user_wrapper'>
            <div className='add-user'>
                <div className='add-user_head-block'>
                    <div className='add-user_title'>Редактировать пользователя</div>
                    <span onClick={closeModal} style={{cursor: 'pointer'}} className="material-symbols-outlined">close</span>
                </div>
                <div className='add-user_input-block'>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Логин</div>
                        <input defaultValue={username} onChange={e => setUsername(e.target.value)} placeholder='Логин' className='add-user_input-block-item__input' type="text" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Пароль</div>
                        <input defaultValue={password} onChange={e => setPassword(md5(e.target.value))} placeholder='Пароль' className='add-user_input-block-item__input' type="password" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Город</div>
                        <Select
                            placeholder="Город"
                            components={{ IndicatorSeparator:() => null }}
                            options={regions}
                            value={regions.filter(function(option) {
                                return option.label === city;
                            })}
                            onChange={e => {setCity(e.label); setCityId(e.value)}}
                            styles={customStyles}
                        />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Имя, Фамилия</div>
                        <input defaultValue={name} onChange={e => setName(e.target.value)} placeholder='Имя, Фамилия' className='add-user_input-block-item__input' type="text" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Телефон</div>
                        <input defaultValue={phone} onChange={e => setPhone(e.target.value)} placeholder='Телефон' className='add-user_input-block-item__input' type="text" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Почта</div>
                        <input defaultValue={mail} onChange={e => setMail(e.target.value)} placeholder='Почта' className='add-user_input-block-item__input' type="text" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Telegram</div>
                        <input defaultValue={tg} onChange={e => setTg(e.target.value)} placeholder='Telegram логин' className='add-user_input-block-item__input' type="text" />
                    </div>
                </div>
                <div className='add-user_bottom-block'>
                    <div onClick={() => {updateUser({username, password, city, cityId, name, phone, mail, tg}); closeModal(); refresh()}} className='add-user_bottom-block__btn'>Сохранить изменения</div>
                </div>
            </div>
        </div>
    );
}

export default UpdateUser;