import React, {useState ,useEffect} from "react";
import './App.css';
import Header from './components/header/header';
import Auth from './screens/auth/auth';

import {BrowserRouter, Routes, Route, Navigate, Link, useLocation} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import UserList from './screens/userlist/userlist';
import Profile from "./screens/profile/profile";
import jwt_decode from "jwt-decode";
import Dashboard from "./screens/dashboard/dashboard";
import Orders from "./screens/orders/orders";
import Finances from "./screens/finances/finances";
import Users from "./screens/users/users";
import './screens/main/main.css'
import { install } from "resize-observer";
import { HashLink } from 'react-router-hash-link';
import { Complaints } from "./screens/complaints/complaints";
import { EnterNewApi } from "./screens/enterNewApi/enternewapi";

// for Safari resize observer
if (typeof window !== "undefined") {
install();
}
install();

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {width, height};
}

export function ScrollToTops(props) {
  const {pathname} = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

export function useWindowDimensions() {
  const [windowDimensions,
      setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const { width } = useWindowDimensions();
  const user = JSON.parse(localStorage.getItem("user"));
  let expired = true;
  const [links, setLinks] = useState([
    {id: 1, title: "Дэшбоард", to: "/", className: "main_tab lnk", icon: "pie_chart", isAdmin: false},
    {id: 2, title: "Прибыль", to: "/#tabs", className: "main_tab lnk", icon: "query_stats", isAdmin: false},
    {id: 3, title: "Пользователи", to: "/users", className: "main_tab lnk", icon: "group", isAdmin: false},
    {id: 4, title: "Заказы", to: "/orders", className: "main_tab lnk", icon: "file_copy", isAdmin: false},
    {id: 5, title: "Финансы", to: "/finances", className: "main_tab lnk", icon: "account_balance_wallet", isAdmin: false},
    {id: 6, title: "Профиль", to: "/profile", className: "main_tab lnk", icon: "account_circle", isAdmin: false},
  ]);
  const [activeLink, setActiveLink] = useState(null);

  if (localStorage.hasOwnProperty("access-token")) {
    const token = localStorage.getItem("access-token");
    const userToken = jwt_decode(token);
    expired = userToken.exp * 1000 < Date.now();
  }

  const logOut = () => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('user');
    window.location.reload();
  }

  const clickHandler = id => {
    setActiveLink(id);
    localStorage.setItem("activeLink", id);
 }

 useEffect(() => {
  const activeLink = localStorage.getItem("activeLink");
  console.log(activeLink);
  if (activeLink) {
    setActiveLink(activeLink);
    }
  }, []);

  return (
    <BrowserRouter>
    <ScrollToTops>
      <div className="App">
        {!expired ? <Header /> : null }
         <div className={!expired ? `flex wrapper` : `flex`}>
         {!expired ? <div className={width <= 800 ? `flex-column flex-align-center` : `flex`}>
            {width <= 1200 ? null :
            // Sidebar menu
              <div className="main_tablist">
                {links.map(link => {
                  return (
                    <HashLink key={link.id} onClick={() => clickHandler(link.id)} title={link.title} to={link.to} className={link.className + (link.id == activeLink ? " main_tab--active" : "")}>
                        <span class="material-symbols-outlined">{link.icon}</span>
                    </HashLink>
                  );
                })}
                {user.username === '7000' ?
                    <HashLink onClick={() => clickHandler(7)} title="Жалобы" to="/complaints" className={"main_tab lnk" + (7 == activeLink ? " main_tab--active" : "")}>
                        <span class="material-symbols-outlined">flag</span>
                    </HashLink>
                 : null}
                 {user.username === '7000' ?
                    <HashLink onClick={() => clickHandler(8)} title="Новый ключ API" to="/newapi" className={"main_tab lnk" + (8 == activeLink ? " main_tab--active" : "")}>
                        <span class="material-symbols-outlined">key</span>
                    </HashLink>
                 : null}
                <div title="Выйти" onClick={() => logOut()} className="main_tab"><span className="material-symbols-outlined">logout</span></div>
              </div>
              }
            </div> : null}

            <div className="main_block">
                <Routes>
                    <Route
                      path="*"
                      element={<Navigate to="/" replace />}
                    />
                    <Route
                      path="/"
                      element={
                        !expired ? (
                          <Dashboard />
                          ) : (
                          <Navigate replace to="/auth" />
                        )
                      }
                    />
                    <Route
                      path="/userlist"
                      element={
                        !expired && user.username === '7000' ? (
                          <UserList />
                          ) : (
                          <Navigate replace to="/auth" />
                        )
                      }
                    />
                    <Route
                      path="/complaints"
                      element={
                        !expired && user.username === '7000' ? (
                          <Complaints />
                          ) : (
                          <Navigate replace to="/auth" />
                        )
                      }
                    />
                    <Route
                      path="/newapi"
                      element={
                        !expired && user.username === '7000' ? (
                          <EnterNewApi />
                          ) : (
                          <Navigate replace to="/auth" />
                        )
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        !expired ? (
                          <Users />
                          ) : (
                          <Navigate replace to="/auth" />
                        )
                      }
                    />
                    <Route
                      path="/orders"
                      element={
                        !expired ? (
                          <Orders />
                          ) : (
                          <Navigate replace to="/auth" />
                        )
                      }
                    />
                    <Route
                      path="/finances"
                      element={
                        !expired ? (
                          <Finances />
                          ) : (
                          <Navigate replace to="/auth" />
                        )
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        !expired ? (
                          <Profile />
                          ) : (
                          <Auth />
                        )
                      }
                    />
                    <Route
                      path="/auth"
                      element={
                        expired ? (
                          <Auth />
                          ) : (
                          <Dashboard />
                        )
                      }
                    />
                </Routes>
              </div>
            <ScrollToTop smooth />
          </div>
        </div>
      </ScrollToTops>
    </BrowserRouter>
  );
}

export default App;
