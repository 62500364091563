import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './userlist.css';
import AddUser from '../../components/add-user/add-user';
import UpdateUser from '../../components/update-user/update-user';
import { server } from '../../var';

const UserList = () => {
    const [users, setUsers] = useState();
    const [item, setItem] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const getUsers = () => {
        axios.get(`${server}/users`)
        .then(response => {
            setUsers(response.data.rows);
        })
        .catch(function (error) {
            console.log('Error JSON', error.toJSON());
            console.log('Error', error.message);
        });
    }

    const onDelete = values => {
        let isDelete = window.confirm("Удалить пользователя?");

        if (isDelete) {
            const username = values;
            axios.post(`${server}/deleteUser`, {username})
            .then(res => {})
            .catch(function (error) {
                console.log('Error JSON', error.toJSON());
                console.log('Error', error.message);
            });
            return getUsers();
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className='users_wrapper'>
        {showModal ? <AddUser closeModal={() => setShowModal(!showModal)} refresh={getUsers} /> : null}
        {showUpdateModal ? <UpdateUser item={item} closeModal={() => setShowUpdateModal(!showUpdateModal)} refresh={getUsers} /> : null}
            <div className='users'>
                <div className='users_header'>
                    <div className='title'>
                        <span style={{marginRight: '7px'}} className="material-symbols-outlined">group</span><span className='d600none'>Пользователи</span>
                    </div>
                    <div onClick={() => setShowModal(!showModal)} className='add-user_btn user-list_filter'>
                        <span style={{marginRight: '7px'}} className="material-symbols-outlined">add_circle</span>
                        Добавить пользователя
                    </div>
                </div>
            </div>
            <div className='table_wrapper_overflow'>
            <table className="table">
                <thead className="table_head">
                    <tr>
                        <th style={{paddingLeft: '10px'}}>
                            <button type="button" className='table_title'>
                                Логин
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Пароль
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Город
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Имя
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Телефон
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Почта
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Telegram
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Изм.
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                    </tr>
                </thead>
               {users?.length > 0 ? 
                <tbody>
                {users.map((item, i) => {
                        return (
                            <tr key={i} className="table_body">
                                <td style={{paddingLeft: '10px'}} className="table-body_item">{item.username}</td>
                                <td className="table-body_item">{item.password}</td>
                                <td className="table-body_item">{item.city}</td>
                                <td className="table-body_item">{item.name}</td>
                                <td className="table-body_item">{item.phone}</td>
                                <td className="table-body_item">{item.mail}</td>
                                <td className="table-body_item">{item.tg}</td>
                                <td className="table-body_item">
                                    <span onClick={() => {setItem(item); setShowUpdateModal(true)}} style={{marginRight: '10px'}} className="material-symbols-outlined btn_table btn_edit">edit</span>
                                    <span onClick={() => onDelete(item.username)} className="material-symbols-outlined btn_table btn_delete">delete</span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                 :
                <tbody><tr><td className='no-data_table' colSpan={9}><div className='no-data_table-item'><span style={{marginRight: '10px'}} className="material-symbols-outlined">folder_off</span>Нет данных</div></td></tr></tbody>
                } 
            </table>
            </div>
            {/* { totalPages !== 0 ?
                <div className="pagination">
                <p className="pagination_text">
                    {page} из {totalPages}
                </p>
                {count <= 1 && totalPages !== 0 ?
                <button className={`pagination_page pagination_page--prev disabled`}>
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                :
                <button onClick={() => onClick()} className={`pagination_page pagination_page--prev`}>
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                }
                
                <div className='pagination_outer'>
                    <div style={{transform: `translate(${xPos}%)`}} className='pagination_block'>
                {[...Array(totalPages).keys()].map((el) => (
                    <button
                        onClick={() => {setPage(el + 1); pagino(el + 1)}}
                        key={el}
                        className={`pagination_page ${page === el + 1
                        ? "active"
                        : ""}`}>
                        {el + 1}
                    </button>
                ))}
                </div>
                    </div>
                    {count === Math.ceil(totalPages / 10) ? 
                    <button className="pagination_page pagination_page--next disabled">
                        <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_forward_ios</span>
                    </button>
                    :
                    <button onClick={() => onClick("left")} className="pagination_page pagination_page--next">
                        <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_forward_ios</span>
                    </button>
                }
            </div>
            :null} */}
        </div>
    );
}

export default UserList;