import React, { useEffect } from 'react';
import { useSetState } from 'react-use';
import axios from 'axios';
import {server} from '../var';

export const AuthContext = React.createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null
}

export const ContextProvider = props => {
  const [state, setState] = useSetState(initialState);

  const setLoginPending = (isLoginPending) => setState({isLoginPending});
  const setLoginSuccess = (isLoggedIn) => setState({isLoggedIn});
  const setLoginError = (loginError) => setState({loginError});

  const login = (username, password) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    fetchLogin( username, password, error => {
      setLoginPending(false);

    if (!error) {
        setLoginSuccess(true);
    } else {
      setLoginError(error);
    }
    })
  }

  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

const fetchLogin = (username, password, callback) => {
    axios.post(`${server}/validatePassword`, {username, password}, {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    })
    .then(res => {
        if (res.data.validation) {
            callback(null);
            console.log(res);
            localStorage.setItem("user", JSON.stringify(res.data.user[0]));
            window.location.replace('/');
            // userData(res.data.user[0]); 
        } else {
          return callback(new Error('Неправильный логин или пароль!'));
        };
        
    })
    .catch(error => {
      console.log('Error JSON', error.toJSON());
      console.log('Error', error.message);
    });

    axios.post(`${server}/user/generateToken`, {username}, {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    })
    .then(res => {
      localStorage.setItem("access-token", res.data.accessToken);
    })
    .catch(error => {
      console.log('Error JSON', error.toJSON());
      console.log('Error', error.message);
    });
}