import React, {useState, useCallback} from 'react';
import InputMask from "react-input-mask";
import axios from 'axios';

const AddUserPs = ({closeModal, refresh}) => {
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [userConfirmed, setUserConfirmed] = useState(false);

    const addUser = (phone, name, surname, password, userConfirmed) => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: 'createUser',
                number: phone,
                first_name: name,
                last_name: surname,
                password: password,
                confirmed: userConfirmed ? 1 : 0,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                console.log(response, "response");
                if(response) {
                    refresh();
                }
            })
            .catch(function (error) {
                console.log('Error JSON', error.toJSON());
                console.log('Error', error.message);
            });
    };

    const checkInputs = () => {
        if (password !== '' && name !== '' && surname != '' && phone !== '') {
            return true;
        }
        return false;
    }

    const onSubmit = (phone, name, surname, password, userConfirmed) => {
        addUser(phone, name, surname, password, userConfirmed);
    }

    const checkBoxConfirmed = () => {
        setUserConfirmed(!userConfirmed);
    };

    const phoneChangeCallback = useCallback(
        (e) => {
            setPhone(e.target.value);
        }, []
    );

    return (
        <div className='add-user_wrapper z-index1000'>
            <div className='add-user'>
                <div className='add-user_head-block'>
                    <div className='add-user_title'>Создать пользователя</div>
                    <span onClick={closeModal} style={{cursor: 'pointer'}} className="material-symbols-outlined">close</span>
                </div>
                <div className='add-user_input-block'>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Телефон</div>
                        <InputMask 
                            mask="+7(999)999-99-99" 
                            value={phone} onChange={phoneChangeCallback} 
                            type="phone" 
                            autoComplete="phone" 
                            className="add-user_input-block-item__input" 
                            placeholder="+7(000)000-00-00" 
                            required
                        />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Имя</div>
                        <input onChange={e => setName(e.target.value)} placeholder='Имя' className='add-user_input-block-item__input' type="text" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Фамилия</div>
                        <input onChange={e => setSurname(e.target.value)} placeholder='Фамилия' className='add-user_input-block-item__input' type="text" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Пароль</div>
                        <input onChange={e => setPassword(e.target.value)} placeholder='Пароль' className='add-user_input-block-item__input' type="password" />
                    </div>
                    <div className='add-user_input-block-item'>
                        <div className='add-user_input-block-item__title'>Подтвержден</div>
                        <label>
                            <input style={{marginTop: '10px'}} checked={userConfirmed} type="checkbox" onChange={checkBoxConfirmed} className="filter_checkbox-column-item" />
                            Подтвержден
                        </label>
                    </div>
                </div>
                <div className='add-user_bottom-block'>
                    {checkInputs() ?
                    <div onClick={() => {onSubmit(phone, name, surname, password, userConfirmed); closeModal()}} className='add-user_bottom-block__btn'>Создать</div>
                    : 
                    <div className='add-user_bottom-block__btn disabled'>Создать</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default AddUserPs;