import React, {useState, useContext} from 'react'
import './auth.css';
import { Link } from 'react-router-dom';
import { useSetState } from 'react-use';
import { AuthContext } from '../../context/auth-context';
import md5 from "md5";

const initialState = {
    username: '',
    password: ''
  }
  
  const Auth = () => {
    const { state: ContextState, login } = useContext(AuthContext);
    const {
      isLoginPending,
      isLoggedIn,
      loginError
    } = ContextState;
    const [state, setState] = useSetState(initialState);
  
    const onSubmit = (e) => {
      e.preventDefault();
      const { username, password } = state;
      login(username, md5(password));
      setState({
        username: '',
        password: ''
      });
    }

    return (
        <div className="wrapper_auth">
        <div className="block">
              <div className='block_logo'>ПоедемСами.рф<br/>Dashboard</div>
            <form onSubmit={onSubmit} style={{height: "450px"}} className="block_auth">
                <div className="block_title">Войти</div>
                    { isLoginPending && <div>Пожалуйста подождите...</div> }
                    { isLoggedIn && <div>Входим...</div> }
                    { loginError && <div>{loginError.message}</div>}
                    {loginError && localStorage.getItem("access-token") ? localStorage.removeItem("access-token") : null}
                <div className="block_inputs">
                    <input 
                      onChange={e => setState({username: e.target.value})} 
                      value={state.username} 
                      style={{marginBottom: "15px"}} placeholder="Логин" className="input" type="text" />
                    <input 
                      onChange={e => setState({password: e.target.value})} 
                      value={state.password} 
                      className="input" placeholder="Пароль" type="password" />
                    {/* <Link className='auth_link link' to='/reg'>Нет аккаунта?</Link> */}
                </div>
                <input className="block_btn" type="submit" value="Войти"></input>
            </form>
        </div>
    </div>
    );
}

export default Auth;