import React, {useState} from 'react'
import './header.css'
import { Link } from 'react-router-dom';
import { useWindowDimensions } from '../../App';
import BurgerMenu from '../burger-menu/burger-menu';

const Header = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { width } = useWindowDimensions();

    const [showBurgerMenu, setShowBurgerMenu] = useState(false);

    return (
        <div className='header'>
            {width <= 1200 ? 
            <div className='header-block_links'>
                <Link to="/" className="block_logo header-block_logo lnk">ПоедемСами.рф</Link>
                {showBurgerMenu ?
                <span style={{cursor: "pointer"}} onClick={() => setShowBurgerMenu(!showBurgerMenu)} className="material-symbols-outlined burger_button">close</span>
                :
                <span style={{cursor: "pointer"}} onClick={() => setShowBurgerMenu(!showBurgerMenu)} className="material-symbols-outlined burger_button">menu</span>}
            </div>
            : 
            <>
            {/* <div className='header-block_links'> */}
                <Link to="/" className="block_logo header-block_logo lnk">ПоедемСами.рф</Link>
                <div>
                    <Link to="/" className='header-block_link lnk'>Главная</Link>
                    <Link to="/profile" className='header-block_link lnk'>Мой Профиль</Link>
                    {user.username === '7000' ? 
                        <Link to="/userlist" className='header-block_link lnk'>Список пользователей</Link>
                    : null}
                </div>
            {/* </div> */}
            <Link to="/profile" className='header-block_profile lnk link'>
                <div className='header_block_image'>
                    <img className='header_block_image-profile' src={require("../../assets/profile.svg").default} />
                    {/* <span className="material-symbols-outlined">account_circle</span> */}
                </div>
                <div className='flex-column'>
                    <div className='header_block_username'>{user.name}</div>
                    {/* <UserAcceptTag /> */}
                </div>
            </Link>
            </>
            }
            <BurgerMenu user={user} isOpen={showBurgerMenu} onClick={() => setShowBurgerMenu(!showBurgerMenu)} />
        </div>
    );
}

export default Header;