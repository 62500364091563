import React, {useState, useEffect, useContext} from 'react'
import usePagination from "../../hooks/usePagination";
import { thousandSeparator } from '../dashboard/dashboard';
import { useSortableData } from '../users/users';
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select'
import { LoadingCards, LoadingSkeletonTableOrders } from '../../components/loading-skeleton/loading-skeleton';
import { TableCard } from '../../components/table/table';
import { useWindowDimensions } from '../../App';
import ru from "date-fns/locale/ru";
import './orders.css'
import "react-datepicker/dist/react-datepicker.css";
registerLocale("ru", ru);

const axios = require('axios').default;

const Orders = () => {
    const { width } = useWindowDimensions();
    const user = JSON.parse(localStorage.getItem("user"));
    const [result, setResult] = useState([]);
    const [resultStat, setResultStat] = useState([]);
    const [resultTotal, setResultTotal] = useState([]);
    const [cities, setCities] = useState([]);
    const [region, setRegion] = useState('');
    const [sort, setSort] = useState('');
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateCheck, setDateCheck] = useState(false);
    const [startDate, endDate] = dateRange;
    const [showFilter, setShowFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingStats, setLoadingStats] = useState(true);
    const [xPos, setXpos] = useState(0);
    const [count, setCount] = useState(1);
    const [showFullInput, setShowFullInput] = useState(false);

    const getOrders = (page) => {
        let filter = {}, sDate, eDate;
        // TIME MANAGE;
        var dd = String(new Date(startDate).getDate()).padStart(2, '0');
        var mm = String(new Date(startDate).getMonth() + 1).padStart(2, '0');
        var yyyy = new Date(startDate).getFullYear();
        sDate = yyyy + '-' + mm + '-' + dd;

        var dd = String(new Date(endDate).getDate()).padStart(2, '0');
        var mm = String(new Date(endDate).getMonth() + 1).padStart(2, '0');
        var yyyy = new Date(endDate).getFullYear();
        eDate = yyyy + '-' + mm + '-' + dd;

        if (sDate == "1970-01-01" && eDate == "1970-01-01") {
            setDateCheck(true);
        }

        if (region !== '' && user.username === '7000') {
            filter["region"] = region;
        }

        if (user.username !== '7000') {
            filter["region"] = user.cityId;
        }

        if (sDate !== "1970-01-01" && eDate !== "1970-01-01") {
            filter.date = {};
            filter.date.start_date = sDate;
            filter.date.end_date = eDate;
            setDateCheck(false);
        }

        if (sort !== "") {
            filter["order"] = sort;
        }

        if (status !== "") {
            filter["status"] = status;
        }
        
        if (type !== "") {
            filter["type"] = type;
        }

        if (filter) {
            filter = JSON.stringify(filter);
        }

        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: 'get_dashboard_orders_list',
                per_page: 50,
                page: page ? page : 1,
                filters: filter,                
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setResultTotal(response.data.total_bookings)
                setResult(response.data.post);
                setLoading(false);
            })
            .catch(function (response) {
                console.log(response.err);
            });

    };

    const getOrdersStat = () => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: 'get_dashboard_orders_list',
                per_page: 1,
                page: 1,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setResultStat(response.data);
                setLoadingStats(false);
            })
            .catch(function (response) {
                console.log(response.err);
            });
    };

    const getRegions = () => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data:{
                type: "get_regions"
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setCities(response.data);
                setLoadingStats(false);
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    };

    let regions = Object.values(cities).map(function(item, index) {
        const options = {index: index, value: item.term_id, label: item.name};
        return options;
    });

    let sorts = [
        {index: 0, value: "", label: "----По новизне----"}, 
        {index: 1, value: "old_first", label: "Сначала старые"}, 
        {index: 2, value: "new_first", label: "Сначала новые"}, 
    ];

    let statuses = [
        {index: 0, value: "", label: "----По статусу----"}, 
        {index: 1, value: "paid", label: "Оплаченные"}, 
        {index: 2, value: "confirmed", label: "Ожидает оплаты"}, 
        {index: 3, value: "expired", label: "Просроченные"}, 
        {index: 4, value: "cancelled", label: "Отменненые"}, 
        {index: 5, value: "owner_reservations", label: "Бронь владельца"}, 
        {index: 6, value: "waiting", label: "Ожидает подтверждения владельца"}, 
    ];

    let types = [
        {index: 0, value: "", label: "----По категории----"}, 
        {index: 1, value: "car", label: "Авто"}, 
        {index: 2, value: "house", label: "Жилье"}, 
        {index: 3, value: "activity", label: "Туризм"}, 
    ];

    const changeRegionFunc = (region) => {
        setRegion(region);
    }
    const changeSortFunc = (sort) => {
        setSort(sort);
    }
    const changeStatusFunc = (status) => {
        setStatus(status);
    }
    const changeTypesFunc = (type) => {
        setType(type);
    }

      const {
        firstContentIndex,
        lastContentIndex,
        nextPage,
        prevPage,
        page,
        setPage,
        totalPages
    } = usePagination({
        contentPerPage: 50,
        count: resultTotal ?? 0
    });


    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          margin: '4px 0px',
          padding: 5,
        //   color: '#000',
          color: state.isSelected ? 'white' : 'black',
          backgroungColor: state.isSelected ? 'blue' :'#B1DBFB',
          borderRadius: '8px',
        }),
        control: () => ({
          display: 'flex',
          width: width <= 900 ? 270 : 345,
          color: '#000',
          border: '1px solid #C4C4C4',
          borderRadius: '8px'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#000',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)': '',
        })
      }

    const filterCounting = () => {
        let sum = 0;
        if (region !== '' && region !== undefined) {sum = sum + 1}
        if (status !== '') {sum = sum + 1}
        if (dateCheck != true) {sum = sum + 1}
        if (sort !== '') {sum = sum + 1}
        if (type !== '') {sum = sum + 1}
        return sum;
    }

    const clear = () => {
        setRegion('');
        setStatus('');
        setDateRange([null, null]);
        setSort('');
        setType('');
    }

    const refresh = () => {
        setShowFilter(!showFilter);
        setLoading(true);
        getOrders();
    }

    const refreshTable = () => {
        setLoading(true);
        getOrders();
    }

    const pagino = (page) => {
        setLoading(true);
        getOrders(page);
    }

    const {items, requestSort, sortConfig} = useSortableData(result == null || result == undefined ? [] : Object.values(result));

    const onClick = (direction) => {
        let res = totalPages / 10;
        let c = Math.ceil(res)
        setCount(c);
        let plu = count + 1;
        let min = count - 1;
        (direction === "left") ? setXpos(x => x - 100) : setXpos(x => x + 100);
        (direction === "left") ? setCount(plu) : setCount(min);
    }
    
    useEffect(() => {
        getOrders();
        getOrdersStat();
        getRegions();
    }, []);

    return (
        <div className='users_wrapper'>
        {loadingStats ? <LoadingCards /> : <TableCard items={[
                {
                    count: thousandSeparator(resultStat.total_bookings),
                    description: 'Количество заказов',
                    icon: 'PDocument'
                },
                {
                    count: thousandSeparator(resultStat.paid),
                    description: 'Оплаченные',
                    icon: 'Tick'
                },
                {
                    count: thousandSeparator(resultStat.canceled),
                    description: 'Отмененные',
                    icon: 'Close'
                },
                {
                    count: thousandSeparator(resultStat.waiting),
                    description: 'В ожидании',
                    icon: 'Clock'
                },
                // {
                //     count: thousandSeparator(dataStats.reservation),
                //     description: 'В ожидании',
                //     icon: 'Clock'
                // },
        ]} />}
            <div className='users'>
                <div className='users_header'>
                    <div className='title'>
                        <span
                            style={{
                            marginRight: '7px'
                            }}
                            className="material-symbols-outlined">file_copy</span><span className='d600none'>Заказы</span></div>
                    <div style={{alignItems: 'center'}} className='flex'>
                    <div title='Обновить таблицу' style={{marginRight: '7px'}} className='table_input-btn'>
                        <span onClick={refreshTable} class="material-symbols-outlined">refresh</span>
                    </div>
                    <div title='Фильтры' className='flex-column'>
                        <div onClick={() => setShowFilter(!showFilter)} className='users_filter'>
                            Фильтры
                            {filterCounting() == '0' ? null : <div className='filter_counter'>{filterCounting()}</div>}
                            <span style={{marginLeft: '7px', display: 'flex', alignItems: 'center'}}>
                                {showFilter ? 
                                <span className="material-symbols-outlined">close</span>
                                : <span className="material-symbols-outlined">tune</span>}
                            </span>
                        </div>
                        <div>{showFilter ? 
                            <div className="filter filter-orders">
                            <div className='flex-column'>
                            <div 
                             className="filter_block">
                            {user.username === '7000' ? 
                                <Select
                                    placeholder="Город"
                                    isSearchable={false}
                                    components={{ IndicatorSeparator:() => null }}
                                    options={regions}
                                    value={regions.filter(function(option) {
                                        return option.value === region;
                                    })}
                                    onChange={e => changeRegionFunc(e.value)}
                                    styles={customStyles}
                                />
                                : null }
                                <DatePicker
                                    locale={ru}
                                    onFocus={(e) => e.target.readOnly = true}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    className='filter_date'
                                    isClearable={true}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Дата"
                                />
                                <Select
                                    placeholder="Самые новые"
                                    isSearchable={false}
                                    components={{ IndicatorSeparator:() => null }}
                                    options={sorts}
                                    value={sorts.filter(function(option) {
                                        return option.value === sort;
                                    })}
                                    onChange={e => changeSortFunc(e.value)}
                                    styles={customStyles}
                                />
                            </div>
                            <div className="filter_block">
                                <Select
                                    placeholder="Жилье"
                                    isSearchable={false}
                                    components={{ IndicatorSeparator:() => null }}
                                    options={types}
                                    value={types.filter(function(option) {
                                        return option.value === type;
                                    })}
                                    onChange={e => changeTypesFunc(e.value)}
                                    styles={customStyles}
                                />
                                <Select
                                    placeholder="Оплачено"
                                    isSearchable={false}
                                    components={{ IndicatorSeparator:() => null }}
                                    options={statuses}
                                    value={statuses.filter(function(option) {
                                        return option.value === status;
                                    })}
                                    onChange={e => changeStatusFunc(e.value)}
                                    styles={customStyles}
                                />
                            </div>
                            </div>
                            <div style={{justifyContent: 'space-between'}} className='filter_button-block'>
                                    <div style={{marginRight: '10px'}} onClick={clear} className='filter_button-block-btn filter_button-block-btn--reset'>Сбросить фильтры</div>
                                    <div onClick={refresh} className='filter_button-block-btn'>Применить</div>
                                </div>
                            </div>
                         : null}</div>
                        </div>
                        <div title='Поиск по таблице' onClick={() => setShowFullInput(!showFullInput)} className='table_input-btn'><span className="material-symbols-outlined">search</span></div>
                        {showFullInput ? <div className='full-input table_input input'>
                            <input onChange={e => {setSearchTerm(e.target.value)}} className='input_text' placeholder='Поиск по имени, логину, номеру или городу'/>
                            <span style={{cursor: 'pointer'}} onClick={() => setShowFullInput(!showFullInput)} className="material-symbols-outlined">close</span>
                        </div> : null}
                    </div>
                </div>
            </div>
            <div className='table_wrapper_overflow'>
            <table className="table">
                <thead className="table_head">
                    <tr>
                        <th style={{paddingLeft: '10px'}}>
                            <button type="button" onClick={() => requestSort('title')} className='table_title'>
                                Название
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('status')} className='table_title'>
                                Статус
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('booking_author_login')} className='table_title'>
                                Арендатор
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('booking_author_first_name')} className='table_title'>
                                Арендодатель
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('booking_author_phone')} className='table_title'>
                                Телефон арендодателя
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('region')} className='table_title'>
                                Город
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                    </tr>
                </thead>
              {loading ? <LoadingSkeletonTableOrders items={[{},{},{},{},{},{}]} /> 
              :
              <>
              {result == null || result == undefined || result.length == 0 
              ? 
              <tbody>
                    <tr>
                        <td colSpan={2}>
                            <div className='no-data_table-item'>
                                <img className='no-data_folder' src={require("../../assets/empty-folder.png")} alt='no-data'/>
                                <div className='no-data_title'>Нет данных</div>
                                <div className='no-data_subtitle'>Сбросьте фильтры чтобы продолжить</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
              : 
               <tbody>
                            {/* items[key].booking_author_last_name.toLowerCase().includes(searchTerm.toLowerCase()) || */}
                    {Object
                        .keys(items)
                        // .filter(key => (
                        //     items[key].booking_author_login.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        //     items[key].booking_author_first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        //     items[key].booking_author_last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        //     items[key].booking_author_phone.toLowerCase().includes(searchTerm.toLowerCase())
                        //     ))
                        .map((item) => {
                            return (
                                <tr key={items[item].id} className="table_body">
                                    <td style={{width: '30%',paddingLeft: '10px'}} className="table-body_item">
                                        <a title="Название объявления" href={items[item].url} target="_blank" className='table-body_item--link'>
                                            {items[item].title}</a>
                                        </td>
                                    <td className="table-body_item">
                                        {(() => {
                                            switch (items[item].status) {
                                                case "paid":
                                                    return <div title="Заказ оплачен" className="table_body_item-status table_body_item-status--paid">Оплачено</div>;
                                                case "expired":
                                                    return <div title="Заказ был просрочен по оплате" className="table_body_item-status table_body_item-status--expired">Просроченный</div>;
                                                case "owner_reservations":
                                                    return <div title="Владелец бронирует у себя" className="table_body_item-status table_body_item-status--owner-reservations">Бронь владельца</div>;
                                                case "waiting":
                                                    return <div title="Заказ в ожидании оплаты" className="table_body_item-status table_body_item-status--waiting">Ждет оплаты</div>;
                                                case "cancelled":
                                                    return <div title="Заказ отменен" className="table_body_item-status table_body_item-status--cancelled">Отменен</div>;             
                                            }
                                        })()}
                                    </td>
                                    <td
                                        className="table-body_item">
                                        <div title="Арендатор объявления" className="">{items[item].booking_author_login}</div></td>
                                    <td title="Арендодатель объявления" className="table-body_item">
                                        {items[item].booking_author_avatar
                                            ? <img className='table-body_img' src={items[item].booking_author_avatar}/>
                                            : <span className="material-symbols-outlined no-img_table">account_circle</span>}
                                        {items[item].booking_author_first_name
                                            ? <span>
                                                    {items[item].booking_author_first_name}&#160;
                                                    {items[item].booking_author_last_name}
                                                </span>
                                            : <span className='no-data'>
                                                Нет имени
                                            </span>}
                                    </td>
                                    {items[item].booking_author_phone
                                        ? <td title="Номер телефона" className="table-body_item">{items[item].booking_author_phone}</td>
                                        : <td className="table-body_item no-data">Нет номера</td>}
                                    {items[item].region
                                        ? <td style={{
                                        paddingRight: '20px'
                                    }} className="table-body_item">{items[item].region}</td>
                                        : <td style={{
                                        paddingRight: '20px'
                                    }} className="table-body_item no-data">Город не указан</td>}
                                </tr>
                            )
                        })}    
              </tbody>
              }
            </>
            }
            </table>
            </div>
            { totalPages !== 0 ?
                <div className="pagination">
                <div className="pagination_text">
                    {page} / {totalPages}
                </div>
                {count <= 1 ?
                <button className={`pagination_page pagination_page--prev disabled`}>
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                :
                <button onClick={() => onClick()} className={`pagination_page pagination_page--prev`}>
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_back_ios_new</span>
                </button>
                }
                
                <div className='pagination_outer'>
                    <div style={{transform: `translate(${xPos}%)`}} className='pagination_block'>
                    {[...Array(totalPages).keys()].map((el) => (
                        <button
                            onClick={() => {setPage(el + 1); pagino(el + 1)}}
                            key={el}
                            className={`pagination_page pagination_page__num ${page === el + 1
                            ? "active"
                            : ""}`}>
                            {el + 1}
                        </button>
                    ))}
                    </div>
                </div>
                {count === Math.ceil(totalPages / 10) ? 
                <button className="pagination_page pagination_page--next disabled">
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_forward_ios</span>
                </button>
                :
                <button onClick={() => onClick("left")} className="pagination_page pagination_page--next">
                    <span style={{fontSize: '22px'}} className="material-symbols-outlined">arrow_forward_ios</span>
                </button>
                }
            </div> 
            : null}
        </div>
    );

}

export default Orders;