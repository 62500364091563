import React from 'react'
import { Link } from 'react-router-dom';
import './modal.css';

const Modal = ({onPress, link, message, title}) => {
    return (
        <div class="wrap">
            <div class="modal js-modal">
                <div class="modal-image">
                    <img className='modal-image_check' src={require("../../assets/check.svg").default} alt="checkIcon" />
                </div>
                <div className='h1'>{title}</div>
                <div className='p'>{message}</div>
                <Link to={link} onClick={onPress} className="btn js-close lnk">Перейти</Link>
            </div>
        </div>
    );
}

export default Modal;