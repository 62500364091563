import React, {useState, useEffect} from 'react';
import './burger-menu.css'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const BurgerMenu = ({user, isOpen, onClick}) => {
    const [links, setLinks] = useState([
        {id: 1, title: "Дэшбоард", to: "/", className: "burger_menu_link lnk", icon: "pie_chart", isAdmin: false},
        {id: 2, title: "Прибыль", to: "/#tabs", className: "burger_menu_link lnk", icon: "query_stats", isAdmin: false},
        {id: 3, title: "Пользователи", to: "/users", className: "burger_menu_link lnk", icon: "group", isAdmin: false},
        {id: 4, title: "Заказы", to: "/orders", className: "burger_menu_link lnk", icon: "file_copy", isAdmin: false},
        {id: 5, title: "Финансы", to: "/finances", className: "burger_menu_link lnk", icon: "account_balance_wallet", isAdmin: false},
        {id: 6, title: "Профиль", to: "/profile", className: "burger_menu_link lnk", icon: "account_circle", isAdmin: false},
      ]);
    const [activeLink, setActiveLink] = useState(null);

    const logOut = () => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('user');
        window.location.reload();
    }

    const clickHandler = id => {
        onClick();
        setActiveLink(id);
        localStorage.setItem("activeLink", id);
    }

    useEffect(() => {
        const activeLink = localStorage.getItem("activeLink");
        console.log(activeLink);
        if (activeLink) {
            setActiveLink(activeLink);
        }
    }, []);
      
    return (
        <div className={`burger_menu ${isOpen ? "burger_menu--show-anim" : "burger_menu--close-anim"}`}>
                  {links.map(link => {
                  return (
                    <HashLink key={link.id} onClick={() => clickHandler(link.id)} title={link.title} to={link.to} className={link.className + (link.id == activeLink ? " burger_menu_link--active" : "")}>
                        <span class="material-symbols-outlined burger_menu_link-icon">{link.icon}</span>
                        {link.title}
                    </HashLink>
                  );
                })}
                {user.username === '7000' ?
                <>
                    <HashLink onClick={() => clickHandler(8)} title="Список пользователей" to="/userlist" className={"burger_menu_link lnk" + (8 == activeLink ? " burger_menu_link--active" : "")}>
                        <span class="material-symbols-outlined burger_menu_link-icon">manage_accounts</span>Список пользователей
                    </HashLink>
                    <HashLink onClick={() => clickHandler(7)} title="Жалобы" to="/complaints" className={"burger_menu_link lnk" + (7 == activeLink ? " burger_menu_link--active" : "")}>
                        <span class="material-symbols-outlined burger_menu_link-icon">flag</span>Жалобы
                    </HashLink>
                    <HashLink onClick={() => clickHandler(9)} title="Новый API-ключ" to="/newapi" className={"burger_menu_link lnk" + (9 == activeLink ? " burger_menu_link--active" : "")}>
                        <span class="material-symbols-outlined burger_menu_link-icon">key</span>Новый API-ключ
                    </HashLink>
                </>
                 : null}
            <Link onClick={() => logOut()} to="#" className='burger_menu_link lnk'><span className="material-symbols-outlined burger_menu_link-icon">logout</span> Выйти</Link>
        </div>
    );
}

export default BurgerMenu;