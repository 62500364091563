import React, {useState, useEffect} from "react";
import axios from "axios";
import './complaints.css'
import { LoadingSkeletonTableComplaints } from "../../components/loading-skeleton/loading-skeleton";

export const Complaints = () => {
    const [complaints, setComplaints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isRevealed, setIsRevealed] = useState(false);
    const [complaintsOpen, setComplaintsOpen] = useState(false);

    const getComplaints = (count) => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: "getComplaints",
                get_closed: count ?? 0
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(function (response) {
            setLoading(false);
            setComplaints(response.data.sort((a, b) => a > b ? 1 : -1));
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const closeComplaints = (id) => {
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: {
                type: "closeComplaint",
                status: "closed",
                id: id
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(function (response) {})
        .catch(function (error) {
            console.log(error);
        });
    }

    const switchStatus = (stat) => {
        setComplaintsOpen(stat);
        getComplaints(complaintsOpen ? 0 : 1);
        setLoading(true);
    }

    const refresh = () => {
        setLoading(true);
        getComplaints();
    }

    const timeFormat = (seconds) => {
        var date = new Date(1000*seconds);
        return date.toLocaleString();
    } 

    const closeComplaintsId = (id) => {
        closeComplaints(id);
        refresh();
    }

    useEffect(() => {
        getComplaints();
    }, []);

    return (
        <div className='users_wrapper'>
            <div className='users'>
                <div className='users_header'>
                    <div className='title'>
                        <span style={{marginRight: '7px'}} className="material-symbols-outlined">flag</span><span className='d600none'>Жалобы</span>
                    </div>
                    <div style={{alignItems: 'center'}} className="flex">
                        <div style={{width: '25px', height: '100%', cursor: "pointer", marginRight: '10px', padding: '7px', background: '#f0f0f0', borderRadius: '8px'}} onClick={refresh} class="material-symbols-outlined">refresh</div>
                        <div className="complaints__tabs" style={{cursor: 'pointer'}}>
                            <div onClick={() => switchStatus(false)} style={{marginRight: '6px'}} className={`complaints__tab ${complaintsOpen ? "" : "complaints__tab--active"}`}>Открытые</div>
                            <div onClick={() => switchStatus(true)} className={`complaints__tab ${complaintsOpen ? "complaints__tab--active" : ""}`}>Закрытые</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='table_wrapper_overflow'>
            <table className="table">
                <thead className="table_head">
                    <tr>
                        <th style={{paddingLeft: '10px'}}>
                            <button type="button" className='table_title'>
                                id Объекта
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th style={{paddingLeft: '10px'}}>
                            <button type="button" className='table_title'>
                                Тип
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Время
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th width="400px">
                            <button type="button" className='table_title'>
                                Сообщение
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Ссылка
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        <th>
                            <button type="button" className='table_title'>
                                Статус
                                <span className="material-symbols-outlined">arrow_drop_down</span>
                            </button>
                        </th>
                        {complaintsOpen ? null 
                        :
                        <th>
                            <button type="button" className='table_title'>
                            </button>
                        </th>
                        }
                    </tr>
                </thead>
              {loading ? <LoadingSkeletonTableComplaints items={[{},{},{},{},{},{}]} /> 
              :
              <>
               {complaints?.length > 0 ? 
                <tbody>
                {complaints.map((item, i) => {
                        const textLength = item.text.length; 
                        const revealedText = isRevealed ? item.text : item.text.substring(0, 50); 
                        return (
                            <tr key={i} className="table_body">
                                <td style={{paddingLeft: '10px'}} className="table-body_item">{item.subject_id}</td>
                                <td style={{textAlign: "center"}} className="table-body_item">
                                {(() => {
                                    switch (item.type) {
                                        case "user":
                                            return <span style={{color: 'blue'}} className="material-symbols-outlined">person</span>;
                                        case "listing":
                                            return <span style={{color: '#56D11D'}} className="material-symbols-outlined">file_copy</span>;            
                                    }
                                })()}
                                </td>
                                <td className="table-body_item">{timeFormat(item.time)}</td>
                                <td className="table-body_item">
                                { revealedText }
                                {textLength > 100 &&
                                <a onClick={() => setIsRevealed(!isRevealed)}>
                                    { isRevealed ? <span className="complaints__show">Скрыть</span> : <span className="complaints__show">Читать полностью</span> }
                                </a>}
                                </td>
                                <td className="table-body_item"><a className="complaints__link lnk" target="_blank" rel="noreferrer" href={item.url}><span className="complaints__closebtn--icon material-symbols-outlined">link</span>Перейти</a></td>
                                <td className="table-body_item">
                                {(() => {
                                    switch (item.status) {
                                        case "open":
                                            return <div className="table_body_item-status table_body_item-status--paid">Открыт</div>;
                                        case "closed":
                                            return <div className="table_body_item-status table_body_item-status--expired">Закрыт</div>;             
                                    }
                                })()}
                                </td>
                                {complaintsOpen ? 
                                null 
                                :
                                 <td className="table-body_item">
                                    <div onClick={() => closeComplaintsId(item.id)} className="complaints__closebtn">Закрыть</div>
                                </td>}
                            </tr>
                        )
                    })}
                </tbody>
                 :
                <tbody><tr><td className='no-data_table' colSpan={9}><div className='no-data_table-item'><span style={{marginRight: '10px'}} className="material-symbols-outlined">folder_off</span>Нет данных</div></td></tr></tbody>
                } 
                </>}
            </table>
            </div>
        </div>
    );
}