import React from 'react';
import './modal-window.css'

export const ModalWindow = ({onClick}) => {
    return (
        <div onClick={onClick} className='modal-window__wrapper'>
            <div className='modal-window'>
                <div className='modal-window__info'>
                    <div className='modal-window__info-head'>
                        <div className='modal-window__info-title'>Для компьютера</div>
                        <span onClick={onClick} className="material-symbols-outlined modal-window__close">close</span>
                    </div>
                    <div className='modal-window__info-item'>
                        <img className='modal-window__info-item-icon' src={require("../../assets/modal-window/wheel.svg").default} alt='icon'/>
                        <div className='modal-window__info-item-text'>Для детального просмотра, наведите курсор на график, и прокрутите колесиком мыши.</div>
                    </div>
                    <div className='modal-window__info-item'>
                        <img className='modal-window__info-item-icon' src={require("../../assets/modal-window/move-cursor.svg").default} alt='icon'/>
                        <div className='modal-window__info-item-text'>Удерживайте курсор на графике и проводите для перемещения по графику.</div>
                    </div>
                    <div className='modal-window__info-title'>Для мобильной версии</div>
                    <div className='modal-window__info-item'>
                        <img className='modal-window__info-item-icon' src={require("../../assets/modal-window/zoom.svg").default} alt='icon'/>
                        <div className='modal-window__info-item-text'>Для детального просмотра, двумя пальцами на графике раздвигая увеличивайте график.</div>
                    </div>
                    <div className='modal-window__info-item'>
                        <img className='modal-window__info-item-icon' src={require("../../assets/modal-window/hand.svg").default} alt='icon'/>
                        <div className='modal-window__info-item-text'>Удерживайте палец на графике и проводите для перемещения по графику.</div>
                    </div>
                </div>
            </div>
        </div>

    );
}