import React, {useState, useEffect, useRef} from 'react'
import './dashboard.css';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import chartTrendline from 'chartjs-plugin-trendline';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useWindowDimensions } from '../../App';
import { LoadingDashboardCards } from '../../components/loading-skeleton/loading-skeleton';
import { ModalWindow } from '../../components/modal-window/modal-window';
const axios = require('axios').default;

// TABS FOR CHARTS
class Tabs extends React.Component{
    state = {
        activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {
        this.setState({ activeTab: tab });
    };
    render(){
        let content;
        let buttons = [];
        return (
            <div className="dashboard_priceblock">
                {React.Children.map(this.props.children, child =>{
                    buttons.push(child.props.label)
                    if (child.props.label === this.state.activeTab) content = child.props.children
                })}
                <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab}/>
                <div className="dashboard_priceblock-charts">{content}</div>
            </div>
        );
    }
}

const TabButtons = ({buttons, changeTab, activeTab}) =>{
    return(
        <div className="dashboard_priceblock-tabs">
            {buttons.map((button) =>{
                return <a href='#tabs' className={button === activeTab ? 'dashboard_priceblock-tabs-item--active lnk' : 'dashboard_priceblock-tabs-item lnk'} onClick={()=>changeTab(button)}>{button}</a>
            })}
        </div>
    )
}

const Tab = props =>{
    return(
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

const DashboardCard = ({items}) => {
    return (
        <div className='dashboard_block'>
            {items.map((item, index) => (
                <div key={index} className='dashboard_block-card'>
                    <img className='dashboard_block-img' src={require(`../../assets/dashboard-icons/${item.icon}.webp`)}/>
                        <div className='dashboard_block-card-count flex'>{item.new
                                ? <div>+</div>
                                : null}{item.count}</div>
                        <div className='dashboard_block-card-description'>{item.description}</div>
                </div>
            ))}
        </div>
    );
}

// FUNCTION FOR NUMBERS SEPARATE
export const thousandSeparator = (number) => {
    const formattedNumber = Number(Math.round(number+'e2')+'e-2').toLocaleString('ru-RU');
    return formattedNumber;
};

const Dashboard = () => {
    const { width } = useWindowDimensions();
    const user = JSON.parse(localStorage.getItem("user"));
    const [loading, setLoading] = useState(true);
    const [dashboardUsers, setDashboardUsers] = useState([]);
    const [dashboardListings, setDashboardListings] = useState([]);
    const [dashboardFinances, setDashboardFinances] = useState([]);
    const [dashboardDataToday, setDashboardDataToday] = useState([]);
    const [dashboardDataFirstDayMonth, setDashboardDataFirstDayMonth] = useState([]);
    const [dataDashboardProfitToday, setDataDashboardProfitToday] = useState([]);
    const [dataDashboardProfitWeek, setDataDashboardProfitWeek] = useState([]);
    const [dataDashboardProfitMonth, setDataDashboardProfitMonth] = useState([]);
    const [dataDashboardProfitYear, setDataDashboardProfitYear] = useState([]);
    const [showInstructions, setShowInstructions] = useState(false);
    const [showFull, setShowFull] = useState(true);

    // TIME MANAGE
    let today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = '"' + yyyy + '-' + mm + '-' + dd + '"';

    var firstDayMonth = new Date();
    var from = firstDayMonth.setMonth(firstDayMonth.getMonth() - 1);
    var fdd = String(firstDayMonth.getDate()).padStart(2, '0');
    var fmm = String(firstDayMonth.getMonth() + 1).padStart(2, '0');
    firstDayMonth = '"' + yyyy + '-' + fmm + '-' + fdd + '"';

    let monday = new Date();
    let mondayWeek = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() - 7);
    var mDay = String(mondayWeek.getDate()).padStart(2, '0');
    var mMonth = String(mondayWeek.getMonth() + 1).padStart(2, '0');
    monday = '"' + yyyy + '-' + mMonth + '-' + mDay + '"';

    var firstDayYear = new Date(yyyy, 0, 1);
    var yDay = String(firstDayYear.getDate()).padStart(2, '0');
    var yMonth = String(firstDayYear.getMonth() + 1).padStart(2, '0');
    const currentYear = new Date().getFullYear();
    const previousYear =  currentYear-1;
    firstDayYear = '"' + previousYear + '-' + yMonth + '-' + yDay + '"';

    var spanToday = yyyy + '-' + mm + '-' + dd;
    var spanFirstDay = yyyy + '-' + fmm + '-' + fdd;
    let spanMonday = yyyy + '-' + mMonth + '-' + mDay;
    let spanYear = previousYear + '-' + yMonth + '-' + yDay;

    var timeSpanToday = `${spanToday}-${spanToday}`;
    var timeSpanTodayActivity = `${spanToday} - ${spanToday}`;
    var timeSpanFirstOfTheMonth = `${spanFirstDay} - ${spanToday}`;
    var timeFirstOfTheMonthActivity = `${spanFirstDay}-${spanToday}`;
    var timeFirstOfTheWeek = `${spanMonday}-${spanToday}`
    var timeFirstOfTheYear = `${spanYear}-${spanToday}`

    const getDashboard = () => {
        const data = {
            type: 'get_dashboard_stat',
            activity_periods: `[
                {"from": ${today},"to": ${today}}, {"from": ${firstDayMonth},"to": ${today}}
            ]`,
        }

        if (user.username !== '7000') {
            data["region_name"] = user.city;
            data["region_id"] = user.cityId;
        }

        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                setDashboardUsers(response.data.users);
                setDashboardListings(response.data.listings);
                setDashboardFinances(response.data.finances);
                setDashboardDataToday(response.data.activity[timeSpanTodayActivity]);
                setDashboardDataFirstDayMonth(response.data.activity[timeSpanFirstOfTheMonth]);
                setLoading(false);
            })
            .catch(function (response) {
                console.log(response.err);
            });
    };

    // only id
    const getDashboardProfitToday = () => {
        const data = {
            type: 'get_dashboard_profit',
            filters: `{"date": [{"start_date": ${today}, "end_date": ${today}}]}`
        }

        if (user.username !== '7000') {
            data["region_id"] = user.cityId;
        }

        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                if (response.data[timeSpanToday][spanToday] !== [] && response.data[timeSpanToday][spanToday] !== undefined) {
                    setDataDashboardProfitToday(response.data[timeSpanToday][spanToday]);
                }
                setLoading(false);
            })
            .catch(function (response) {
                console.log(response.err);
            });
    };

    const getDashboardProfitWeek = () => {
        const data = {
            type: 'get_dashboard_profit',
            filters: `{"date": [{"start_date": ${monday}, "end_date": ${today}}]}`
        }

        if (user.username !== '7000') {
            data["region_id"] = user.cityId;
        }
        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                if (response.data[timeFirstOfTheWeek] !== [] && response.data[timeFirstOfTheWeek] !== undefined) {
                    setDataDashboardProfitWeek(response.data[timeFirstOfTheWeek]);
                }
                setLoading(false);
            })
            .catch(function (response) {
                console.log(response.err);
            });
    };

    const getDashboardProfitMonth = () => {
        const data = {
            type: 'get_dashboard_profit',
            filters: `{"date": [{"start_date": ${firstDayMonth}, "end_date": ${today}}]}`
        }

        if (user.username !== '7000') {
            data["region_id"] = user.cityId;
        }

        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                if (response.data[timeFirstOfTheMonthActivity] !== [] && response.data[timeFirstOfTheMonthActivity] !== undefined) {
                    setDataDashboardProfitMonth(response.data[timeFirstOfTheMonthActivity]);
                }
                setLoading(false);
            })
            .catch(function (response) {
                console.log(response.err);
            });
    };

    const getDashboardProfitYear = () => {
        const data = {
            type: 'get_dashboard_profit',
            filters: `{"date": [{"start_date": ${firstDayYear}, "end_date": ${today}}]}`
        }

        if (user.username !== '7000') {
            data["region_id"] = user.cityId;
        }

        axios({
            method: "POST",
            url: "https://xn--80ahcbqwbkiz.xn--p1ai/custom.php",
            data: data,
            headers: {
                "Content-Type": "multipart/form-data"
            }
            })
            .then(function (response) {
                if (response.data[timeFirstOfTheYear] !== [] && response.data[timeFirstOfTheYear] !== undefined) {
                    setDataDashboardProfitYear(response.data[timeFirstOfTheYear]);
                }
                setLoading(false);
            })
            .catch(function (response) {
                console.log(response.err);
            });
    };

    // DATA FOR BARCHART
    // ChartJS.plugins.register(chartTrendline);
    ChartJS.register(
        Filler,
        chartTrendline,
        zoomPlugin,
        ArcElement,
        CategoryScale,
        LinearScale,
        Title,
        Tooltip,
        Legend,
        PointElement,
        BarElement,
        LineElement,
        {
            id: 'uniqueid5',
            afterDraw: function (chart, easing) {
              if (chart.tooltip?._active && chart.tooltip?._active.length) {
                const activePoint = chart.tooltip?._active[0];
                const ctx = chart.ctx;
                const x = activePoint.element.x;
                const topY = chart.scales.y.top;
                const bottomY = chart.scales.y.bottom;
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 2;
                ctx.strokeStyle = '#8D8D8D';
                ctx.stroke();
                ctx.restore();
              }
            }
          }
    );
      
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        tooltips: {
            mode: 'index',
            intersect: false
        },
        hover: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
            zoom: {
                mode: 'x',
                senstivity: 0.00001,
                pan: {
                    enabled: true,
                    mode: 'x',
                },
                limits: {
                    x: {min: -200, max: 200, minRange: 5},
                    y: {min: -200, max: 200, minRange: 5}
                },
                zoom: {
                    senstivity: 0.00001,
                    wheel: {
                        enabled: true,
                        speed: 0.1,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
            } 
        },
          
        scales: {
            x: {
                beginAtZero: true,
                reverse: true
            }
          }
      };
      
    // DATA FOR PIE CHART
    const pieChartOneDay = {
        labels: ['Жилье', 'Авто', 'Туризм'],
        datasets: [
            {
            label: '# of Votes',
            data: [dataDashboardProfitToday.total_house_paid ?? 0, dataDashboardProfitToday.total_car_paid ?? 0, dataDashboardProfitToday.total_activity_paid ?? 0],
            backgroundColor: [
                'rgba(255, 99, 132)',
                'rgba(54, 162, 235)',
                'rgba(120,240,43)',
            ],
            borderWidth: 1,
            },
        ],
    };
    
    // DATA FOR BAR CHART 1 DAY
    const dataOneDay = {
        labels: ['', '', ''],
        datasets: [
            {
                label: 'Жилье',
                data: [dataDashboardProfitToday.total_house_paid ?? 0],
                backgroundColor: 'rgba(255, 99, 132)',
            },
            {
                label: 'Авто',
                data: [dataDashboardProfitToday.total_car_paid ?? 0],
                backgroundColor: 'rgba(54, 162, 235)',
            },
            {
                label: 'Туризм',
                data: [dataDashboardProfitToday.total_activity_paid ?? 0],
                backgroundColor: 'rgba(120,240,43)',
            },
        ],
    };

    // DATA FOR BAR CHART 1 MONTH
    const labelsWeek = Object.keys(dataDashboardProfitWeek);
    let valueActivityWeek = [], valueCarWeek = [], valueHouseWeek = [];

    for (const [key, value] of Object.entries(dataDashboardProfitWeek)) {

        if (value.total_activity_paid) {
            valueActivityWeek.push(value.total_activity_paid);
        } else {
            valueActivityWeek.push(0);
        }
        if (value.total_car_paid) {
            valueCarWeek.push(value.total_car_paid);
        } else {
            valueCarWeek.push(0);
        }
        if (value.total_house_paid) {
            valueHouseWeek.push(value.total_house_paid);
        } else {
            valueHouseWeek.push(0);
        }
    }
    
    const dataWeek = {
        labels: labelsWeek,
        datasets: [
          {
            label: 'Жилье',
            data: valueHouseWeek,
            backgroundColor: 'rgba(255, 99, 132)',
          },
          {
            label: 'Авто',
            data: valueCarWeek,
            backgroundColor: 'rgba(54, 162, 235)',
          },
          {
            label: 'Туризм',
            data: valueActivityWeek,
            backgroundColor: 'rgba(120,240,43)',
          },
        ],
    };

    // DATA FOR BAR CHART 1 MONTH
    const labels = Object.keys(dataDashboardProfitMonth);
    let valueActivity = [], valueCar = [], valueHouse = [];

    for (const [key, value] of Object.entries(dataDashboardProfitMonth)) {

        if (value.total_activity_paid) {
            valueActivity.push(value.total_activity_paid);
        } else {
            valueActivity.push(0);
        }
        if (value.total_car_paid) {
            valueCar.push(value.total_car_paid);
        } else {
            valueCar.push(0);
        }
        if (value.total_house_paid) {
            valueHouse.push(value.total_house_paid);
        } else {
            valueHouse.push(0);
        }
    }

    const dataMonth = {
        labels,
        datasets: [
          {
            label: 'Жилье',
            data: valueHouse,
            backgroundColor: 'rgba(255, 99, 132, .1)',
            borderColor: 'rgba(255, 99, 132)',
            fill: true,
            lineTension: 0.3,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
          },
          {
            label: 'Авто',
            data: valueCar,
            backgroundColor: 'rgba(54, 162, 235, .1)',
            borderColor: 'rgba(54, 162, 235)',
            fill: true,
            lineTension: 0.3,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
          },
          {
            label: 'Туризм',
            data: valueActivity,
            backgroundColor: 'rgba(120,240,43, .1)',
            borderColor: 'rgba(120,240,43)',
            fill: true,
            lineTension: 0.3,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
          },
        ],
      };

      // YEAR
      const optionsYear = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
          },
          tooltips: {
            mode: 'index',
            intersect: false
        },
          hover: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },   
            zoom: {
                mode: 'x',
                pan: {
                    enabled: true,
                    mode: 'x',
                  },
                limits: {
                    x: {min: -200, max: 200, minRange: 5},
                    y: {min: -200, max: 200, minRange: 5}
                  },
                  zoom: {
                    speed: 0.1,
                    sensitivity: 3,
                    mode: 'x',
                    wheel: {
                        enabled: true,
                        speed: 0.1,
                    },
                    pinch: {
                        enabled: true,
                    },
                }
            } 
        },
        scales: {
            x: {
                beginAtZero: true,
                reverse: true
            }
          }
      };

      const labelsYear = Object.keys(dataDashboardProfitYear);
      let valueActivityYear = [], valueCarYear = [], valueHouseYear = [];
  
      for (const [key, value] of Object.entries(dataDashboardProfitYear)) {
  
          if (value.total_activity_paid) {
            valueActivityYear.push(value.total_activity_paid);
          } else {
            valueActivityYear.push(0);
          }
          if (value.total_car_paid) {
            valueCarYear.push(value.total_car_paid);
          } else {
            valueCarYear.push(0);
          }
          if (value.total_house_paid) {
            valueHouseYear.push(value.total_house_paid);
          } else {
            valueHouseYear.push(0);
          }
      }

      const dataYear = {
        labels: labelsYear,
        datasets: [
            {
              label: 'Жилье',
              data: valueHouseYear,
              backgroundColor: 'rgba(255, 99, 132, .1)',
              borderColor: 'rgba(255, 99, 132)',
              fill: "origin",
              lineTension: 0.3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              trendlineLinear: {
                lineStyle: "solid",
                width: 1
              }
            },
            {
              label: 'Авто',
              data: valueCarYear,
              backgroundColor: 'rgba(54, 162, 235, .1)',
              borderColor: 'rgba(54, 162, 235)',
              fill: "origin",
              lineTension: 0.3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              trendlineLinear: {
                lineStyle: "solid",
                width: 1
              }
            },
            {
              label: 'Туризм',
              data: valueActivityYear,
              backgroundColor: 'rgba(120,240,43, .1)',
              borderColor: 'rgba(120,240,43)',
              fill: "origin",
              lineTension: 0.3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              trendlineLinear: {
                lineStyle: "solid",
                width: 1
              }
            },
        ]
      };

    //FUNCTION
    const chartRef = React.useRef(null);

    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    useEffect(() => {
        getDashboard();
        getDashboardProfitToday();
        getDashboardProfitWeek();
        getDashboardProfitMonth();
        getDashboardProfitYear();
    }, []);

    return (
        <div className='dashboard'>
            <details open>
                <summary>Пользователи</summary>
                {loading ? <LoadingDashboardCards /> 
                : 
                <DashboardCard
                    items={[
                    {
                        icon: 'Profile',
                        count: thousandSeparator(dashboardUsers.total_users),
                        description: 'Активных пользователей'
                    }, {
                        icon: 'Close',
                        count: thousandSeparator(dashboardUsers.not_confirmed_users),
                        description: 'Не подтвержденных пользователей'
                    }, {
                        icon: 'Tick',
                        count: thousandSeparator(dashboardUsers.confirmed_users),
                        description: 'Подтвержденных пользователей'
                    }, {
                        icon: 'Clock',
                        count: thousandSeparator(dashboardUsers.users_on_validation),
                        description: 'Заявка на авторизацию'
                    }, {
                        icon: 'blocked-users',
                        count: thousandSeparator(dashboardUsers.blocked_users),
                        description: 'Заблокированных пользователей'
                    }
                ]}/>
                }
            </details>

            <details open>
                <summary>Заказы</summary>
                {loading ? <LoadingDashboardCards /> 
                : 
                <DashboardCard
                    items={[
                    {
                        icon: 'Document',
                        count: thousandSeparator(dashboardListings.total_listings),
                        description: 'Объявлений всего'
                    }, {
                        icon: 'Activity',
                        count: thousandSeparator(dashboardListings.active_listings),
                        description: 'Активных объявлений'
                    }, {
                        icon: 'Clock',
                        count: thousandSeparator(dashboardListings.listings_on_moderation),
                        description: 'Объявлений на модерации'
                    }, {
                        icon: 'Folder',
                        count: thousandSeparator(dashboardListings.archived_listings),
                        description: 'Объявлений в архиве'
                    }, {
                        icon: 'Paper',
                        count: thousandSeparator(dashboardListings.total_bookings),
                        description: 'Заказов всего'
                    }, {
                        icon: 'Close',
                        count: thousandSeparator(dashboardListings.canceled_bookings),
                        description: 'Отмененных заказов'
                    }
                ]}/>
                }
            </details>

            <details open>
                <summary>Финансы</summary>
                {loading ? <LoadingDashboardCards /> 
                : 
                <DashboardCard
                    items={[
                    {
                        icon: 'Graph',
                        count: thousandSeparator(dashboardFinances.paid_bookings),
                        description: 'Оплаченных заказов'
                    }, {
                        icon: 'Buy',
                        count: thousandSeparator(dashboardFinances.total_bookings_price),
                        description: 'Сумма заказов (₽)'
                    }, {
                        icon: 'Wallet',
                        count: thousandSeparator(dashboardFinances.total_earned_by_owners),
                        description: 'Заработано хозяевами (₽)'
                    }, {
                        icon: 'Discount',
                        count: thousandSeparator(dashboardFinances.paid_with_commission),
                        description: 'Оплачено с комиссиями (₽)'
                    }, {
                        icon: 'Close',
                        count: thousandSeparator(dashboardFinances.paid_on_canceled_listings),
                        description: 'Выплачено по отказам (₽)'
                    }, {
                        icon: 'Wallet',
                        count: thousandSeparator(dashboardFinances.net_profit),
                        description: 'Чистой прибыли (₽)'
                    }
                ]}/>
                }
            </details>

            <details open>
                <summary>Сегодня</summary>
                {loading ? <LoadingDashboardCards /> 
                : 
                <DashboardCard
                    items={[
                    {
                        icon: 'Profile',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.new_users),
                        description: 'Новых пользователей'
                    }, {
                        icon: 'PDocument',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.new_listings),
                        description: 'Новых объявлений'
                    }, {
                        icon: 'Paper',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.new_bookings),
                        description: 'Новых заказов'
                    }, {
                        icon: 'Clock',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.bookings_on_confirmation),
                        description: 'Заказов в ожидании'
                    }, {
                        icon: 'Close',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.canceled_bookings),
                        description: 'Отмененных заказов'
                    }, {
                        icon: 'Close',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.paid_on_canceled),
                        description: 'Выплачено по отказам (₽)'
                    }, {
                        icon: 'Graph',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.paid_bookings),
                        description: 'Оплаченных заказов'
                    }, {
                        icon: 'Buy',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.bookings_total_price),
                        description: 'Сумма заказов (₽)'
                    }, {
                        icon: 'Wallet',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.earned_by_owners),
                        description: 'Заработанно хозяевами (₽)'
                    }, {
                        icon: 'Discount',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.paid_with_commissions),
                        description: 'Оплачено с комиссиями (₽)'
                    }, {
                        icon: 'Wallet',
                        new: true,
                        count: thousandSeparator(dashboardDataToday.net_profit),
                        description: 'Чистой прибыли (₽)'
                    }
                    ]}/>
                }
            </details>

            <details open>
                <summary>С начала месяца</summary>
                {loading ? <LoadingDashboardCards /> 
                : 
                <DashboardCard
                    items={[
                    {
                        icon: 'Profile',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.new_users),
                        description: 'Новых пользователей'
                    }, {
                        icon: 'PDocument',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.new_listings),
                        description: 'Новых объявлений'
                    }, {
                        icon: 'Paper',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.new_bookings),
                        description: 'Новых заказов'
                    }, {
                        icon: 'Clock',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.bookings_on_confirmation),
                        description: 'Заказов в ожидании'
                    }, {
                        icon: 'Close',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.canceled_bookings),
                        description: 'Отмененных заказов'
                    }, {
                        icon: 'Close',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.paid_on_canceled),
                        description: 'Выплачено по отказам (₽)'
                    }, {
                        icon: 'Graph',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.paid_bookings),
                        description: 'Оплаченных заказов'
                    }, {
                        icon: 'Buy',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.bookings_total_price),
                        description: 'Сумма заказов (₽)'
                    }, {
                        icon: 'Wallet',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.earned_by_owners),
                        description: 'Заработанно хозяевами (₽)'
                    }, {
                        icon: 'Discount',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.paid_with_commissions),
                        description: 'Оплачено с комиссиями (₽)'
                    }, {
                        icon: 'Wallet',
                        new: true,
                        count: thousandSeparator(dashboardDataFirstDayMonth.net_profit),
                        description: 'Чистой прибыли (₽)'
                    }
                    ]}/>
                }
            </details>

                <div id="tabs" className='dashboard_title-profit-block'>
                    <div className="dashboard_title">Прибыль</div>
                    <div onClick={() => setShowInstructions(!showInstructions)} className='dashboard_chart-instruction'>Инструкция <span style={{marginLeft: '5px'}} className="material-symbols-outlined">info</span></div>
                    {showInstructions ? 
                    <ModalWindow onClick={() => setShowInstructions(!showInstructions)} />
                    : null}
                </div>
                <Tabs>
                    <Tab className="dashboard_priceblock-tabs-item" label="За день">
                        {dataDashboardProfitToday.total_house_paid || dataDashboardProfitToday.total_car_paid || dataDashboardProfitToday.total_activity_paid ? 
                        <div className="dashboard_priceblock-piechart">
                            <div>
                                <Doughnut data={pieChartOneDay} />
                            </div>
                            <div>
                                <Bar data={dataOneDay} />
                            </div>
                        </div>
                        :   <div className='no-data-block'>
                                <img className='no-data-img' src={require("../../assets/no-data.png")} alt='no-data'/>
                                <h2>За сегодня прибыли нет</h2>
                            </div>}
                    </Tab>
                    <Tab label="За неделю">
                    <div className='dashboard_chart-year'>
                    {/* <span onClick={handleResetWeekZoom} className="material-symbols-outlined dashboard_chart-icons">refresh</span> */}
                        <div className="dashboard_priceblock-linechart">
                            <Bar options={options} data={dataWeek} />
                        </div>
                        </div>
                    </Tab>
                    <Tab label="За месяц">
                    <div className='dashboard_chart-year'>
                    {/* <span onClick={handleResetMonthZoom} className="material-symbols-outlined dashboard_chart-icons">refresh</span> */}
                        <div className="dashboard_priceblock-linechart">
                            <Line 
                             options={options}
                             data={dataMonth} />
                        </div>
                    </div>
                    </Tab>
                    <Tab label="За год">
                        <div className='dashboard_chart-year'>
                        <span>
                            <span onClick={handleResetZoom} className="material-symbols-outlined dashboard_chart-icons">refresh</span>
                            <span onClick={() => setShowFull(!showFull)} className="material-symbols-outlined dashboard_chart-icons">open_in_full</span>
                        </span>
                        {showFull ? 
                        <div className="dashboard_priceblock-barchart">
                            <Line 
                            ref={chartRef}
                            options={optionsYear}
                            data={dataYear} />
                        </div>
                        :
                        <div className='dashboard_priceblock-fullscreen'>
                            <a href='#tabs' onClick={() => setShowFull(!showFull)} className="material-symbols-outlined dashboard_fullsize lnk">close</a>
                            <div className="dashboard_priceblock-barchart--fullscreen">
                                <Line 
                                ref={chartRef}
                                options={optionsYear} data={dataYear} />
                            </div>
                        </div>
                        }
                        </div>
                    </Tab>
                </Tabs>
        </div>
    );
}

export default Dashboard;