import React, {useContext} from 'react';
import './profile.css';

const Profile = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <div className='profile'>
            <img className='profile_card__img' src={require('../../assets/profile.svg').default} alt=''/>
            <div className='profile_card'>
                <div className='profile_card__title'>{user.name}</div>
                <div className='profile_card__table'>
                    <div className='profile_card__table-item'>
                        <div className='profile_card__table-item-name'>Логин:</div>
                        <div className='profile_card__table-item-answ'>{user.username}</div>
                    </div>
                    <div className='profile_card__table-item'>
                        <div className='profile_card__table-item-name'>Телефон:</div>
                        <div className='profile_card__table-item-answ'>{user.phone}</div>
                    </div>
                    <div className='profile_card__table-item'>
                        <div className='profile_card__table-item-name'>Город:</div>
                        <div className='profile_card__table-item-answ'>{user.city}</div>
                    </div>
                    <div className='profile_card__table-item'>
                        <div className='profile_card__table-item-name'>Почта:</div>
                        <div className='profile_card__table-item-answ'>{user.mail}</div>
                    </div>
                    <div className='profile_card__table-item'>
                        <div className='profile_card__table-item-name'>Telegram:</div>
                        <div className='profile_card__table-item-answ'>{user.tg}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;